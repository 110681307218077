import React, { useRef, useState } from 'react';
import { css } from '@emotion/core';
import { useLocation } from '@reach/router';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import Container from '../Container';
import Shield from './Shield';
import FooterForm from './ContactForm';
import Instagram from '../../svgs/instagram.svg';
import Facebook from '../../svgs/facebook.svg';
import LinkedIn from '../../svgs/linkedin.svg';
import WeChat from '../../svgs/wechat.svg';
import WhatsApp from '../../svgs/whatsapp.svg';
import Logo from '../Logo';
import QRCode from '../../svgs/qr-code.svg';
import QRCodeWhite from '../../svgs/qr-code-white.svg';
import GooglePlay from '../../svgs/google-play.svg';
import GooglePlayWhite from '../../svgs/google-play-white.svg';
import AppStore from '../../svgs/app-store.svg';
import AppStoreWhite from '../../svgs/app-store-white.svg';
import YouTube from '../../svgs/youtube.svg';
import { Desktop, Tablet } from '../../constants/responsive';
import { menu } from '../../constants/menu';
import { mainColor } from '../../constants/colors';
import { Helmet } from 'react-helmet';
import { useClickAway } from 'react-use';
import { useAppContext } from '../../context/AppContext';
import Blank from '../../components/Blank';
import env from '../../constants/environment';
import ReqDemoBtn from '../ReqDemo';
const Wrapper = ({ children }) => {
  const location = useLocation();
  const isInstitutions = location.pathname.split('/')[3] === 'institutions';
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';
  const isBespoke = location.pathname.split('/')[4] === 'bespoke';

  const textColor = isProfessional ? 'white' : 'black';
  const backgroundColor = isInstitutions
    ? '#f2f2f2'
    : isProfessional
    ? '#14123c'
    : '#f2f2f2';
  return (
    <footer
      css={css`
        display: flex;
        justify-content: center;
        background: ${backgroundColor};
        padding-top: 50px;
        padding-bottom: 60px;
        min-width: 320px;
        color: ${textColor};
        a,
        a:active,
        a:visited {
          color: ${textColor};
          transition: all 0.2s ease;
          text-decoration: none;
        }
        a:hover {
          color: ${mainColor};
        }
      `}
    >
      {children}
    </footer>
  );
};

const FirstRow = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        ${Desktop} {
          flex-direction: row;
        }
      `}
    >
      {children}
    </div>
  );
};

const SiteMap = ({ children, ...rest }) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex: 1;
      flex-wrap: wrap;
    `}
    {...rest}
  >
    {children}
  </div>
);

const SiteMapList = ({ title, productMenu = false }) => {
  const { setBlogTopic } = useAppContext();
  // 【底部目录 - products】，每个子目录对应的跳转url
  const productsUrlMap = {
    'long-term': '/product/asset-allocation',
    // 'smart-stock': '/product/smart-stock',
    'cash-management': '/product/3000',
    // 'stock-trading': '/product/stock-trading',
    raas: '/product/institutions/advisory',
    'Fund-Supermarket': '/product/institutions/fundsuper',
    'smart-fund': '/product/institutions/smartfund',
    'hk-overseas': '/product/bespoke-investors/hk-overseas',
  };
  const stsMap = {
    Digital: '/product/bespoke-investors/bespoke/',
    App: '/product/aqumon',
    fee: '/resources/fee',
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
        height: 132px;
        white-space: nowrap;
        ${Tablet} {
          width: fit-content;
          // min-width: 18%;
          height: 100%;
        }
        ${Desktop} {
          min-width: 0px;
          width: 164px;
          width: auto;
        }
      `}
    >
      {/* 【一级目录-优惠活动】和【一级目录-专业投资者】，需要可点击。其他一级目录维持不可点击 */}
      {title === 'promotions' && (
        <Link
          onClick={() => {
            setTimeout(goTop, 300);
          }}
          to={'/promotions'}
        >
          <b
            css={css`
              margin-bottom: 5px;
            `}
          >
            <FormattedHTMLMessage id={`menu.${title}`} />
          </b>
        </Link>
      )}
      {/* 非【一级目录-优惠活动】和【一级目录-专业投资者】目录 */}
      {!(title === 'promotions') && (
        <b
          css={css`
            margin-bottom: 5px;
          `}
        >
          <FormattedHTMLMessage id={`menu.${title}`} />
        </b>
      )}
      {/* 非【一级目录-products】处理 */}
      {!productMenu &&
        menu[title].map((name) => {
          return (
            <div
              key={name}
              css={css`
                a {
                  padding: 5px 0px;
                  padding-right: 20px;
                  ${Tablet} {
                    padding-right: 0px;
                  }
                  display: inline-block;
                  span {
                    height: 25px;
                    display: flex;
                    align-items: center;
                  }
                }
              `}
              onClick={() => {
                if (title === 'blog') {
                  setBlogTopic(name);
                }
              }}
            >
              {/* 专业投资者 - 大陆客户的链接需要跳出本站。目前资管官网仅有中文版 */}
              {title === 'S-T-S' &&
                (name === 'Mainland' ? (
                  <a
                    href={
                      {
                        live: 'https://www.aqumon.com/cn/zh-cn/',
                        test: 'https://test-toc-v5.aqumon.com/cn/zh-cn/',
                      }[process.env.GATSBY_ENV]
                    }
                  >
                    <FormattedHTMLMessage id={`menu.${name}`} />
                  </a>
                ) : (
                  <Link
                    onClick={() => {
                      setTimeout(goTop, 300);
                    }}
                    to={stsMap[name]}
                  >
                    <FormattedHTMLMessage id={`menu.${name}`} />
                  </Link>
                ))}
              {title !== 'S-T-S' && (
                <Link
                  onClick={() => {
                    setTimeout(goTop, 300);
                  }}
                  to={title === 'blog' ? `/${title}` : `/${title}/${name}`}
                >
                  <FormattedHTMLMessage id={`menu.${name}`} />
                </Link>
              )}
            </div>
          );
        })}
      {/* 【一级目录-products】的特殊处理 */}
      {productMenu &&
        menu['wealthTech'][0]['product'].map((name) => {
          return (
            <div
              key={name}
              css={css`
                a {
                  padding: 5px 0px;
                  padding-right: 20px;
                  ${Tablet} {
                    padding-right: 0px;
                  }
                  display: inline-block;
                  span {
                    height: 25px;
                    display: flex;
                    align-items: center;
                    sup {
                      align-self: flex-start;
                    }
                  }
                }
              `}
              onClick={() => {
                if (title === 'blog') {
                  setBlogTopic(name);
                }
              }}
            >
              {/* 专业投资者 - 大陆客户的链接需要跳出本站。目前资管官网仅有中文版 */}
              {name === 'mainland-china' ? (
                <a
                  href={
                    {
                      live: 'https://www.aqumon.com/cn/zh-cn/',
                      test: 'https://test-toc-v5.aqumon.com/cn/zh-cn/',
                    }[process.env.GATSBY_ENV]
                  }
                >
                  <FormattedHTMLMessage id={`menu.${name}`} />
                </a>
              ) : (
                <Link
                  onClick={() => {
                    setTimeout(goTop, 300);
                  }}
                  to={productsUrlMap[name]}
                >
                  <FormattedHTMLMessage id={`menu.${name}`} />
                </Link>
              )}
            </div>
          );
        })}
    </div>
  );
};

const SecondRow = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        ${Desktop} {
          align-items: center;
          flex-direction: row;
        }
      `}
    >
      {children}
    </div>
  );
};

const SocialMedia = () => {
  const location = useLocation();
  const weChatQRCodeRef = useRef(null);
  const [showWeChat, setShowWeChat] = useState(false);

  useClickAway(weChatQRCodeRef, () => {
    setShowWeChat(false);
  });
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';

  // const isWhite = isProfessional;
  const isWhite = false;
  const fill = isWhite ? 'white' : 'black';
  const iconStyle = css`
    width: 40px;
    cursor: pointer;
    margin-right: 0px;

    ${Desktop} {
      margin-right: 30px;
      margin-left: 0px;
    }
  `;
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 440px;
        margin-bottom: 40px;
        justify-content: space-between;
        ${Tablet} {
          max-width: 100%;
          width: 76%;
        }
        ${Desktop} {
          width: auto;
        }
        * {
          fill: ${fill};
        }
      `}
    >
      <a
        href='https://www.instagram.com/aqumonhk/'
        target='_blank'
        rel='noreferrer'
      >
        <Instagram css={iconStyle} />
      </a>

      <a
        href='https://www.facebook.com/aqumonx'
        target='_blank'
        rel='noreferrer'
      >
        <Facebook css={iconStyle} />
      </a>
      <a
        href='https://www.linkedin.com/company/aqumon/'
        target='_blank'
        rel='noreferrer'
      >
        <LinkedIn css={iconStyle} />
      </a>

      <div
        ref={weChatQRCodeRef}
        css={css`
          position: relative;
          cursor: pointer;
        `}
        onClick={() => {
          console.log('open!');
          setShowWeChat(!showWeChat);
        }}
      >
        {showWeChat && (
          <img
            src={`/footer/wechat-qrcode.jpg`}
            css={css`
              width: 200px;
              height: 200px;
              position: absolute;
              top: -220px;
              left: 50%;
              transform: translateX(-50%);
              ${Desktop} {
                left: calc(50% - 15px);
              }
            `}
            onClick={() => {}}
          />
        )}
        <WeChat css={iconStyle} />
      </div>

      <a href='https://wa.me/85230015836' target='_blank' rel='noreferrer'>
        <WhatsApp css={iconStyle} />
      </a>
      <a
        href='https://www.youtube.com/channel/UCcv2wk_nOBKtb4t0lnOt4vA'
        target='_blank'
        rel='noreferrer'
      >
        <YouTube css={iconStyle} />
      </a>
    </div>
  );
};

const Copyright = () => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        font-size: 10px;
        line-height: 20px;
        flex-direction: column-reverse;
        ${Tablet} {
          flex-direction: row;
          justify-content: space-between;
        }
      `}
    >
      <div>
        © Magnum Research Limited. All Rights reserved.{' '}
        <br
          css={css`
            display: inline;
            ${Desktop} {
              display: none;
            }
          `}
        />
        <span
          onClick={() => {
            window.open('https://beian.miit.gov.cn');
          }}
          css={css`
            cursor: pointer;
          `}
        >
          粤ICP备19147642号
        </span>
      </div>
      <div
        css={css`
          margin-bottom: 20px;
          ${Tablet} {
            text-align: right;
            margin-bottom: 0px;
          }
        `}
      >
        <a href='mailto:info@aqumon.com'>info@aqumon.com</a> | +852 3590 9951
        <span
          css={css`
            display: none;
            ${Desktop} {
              display: inline;
            }
          `}
        >
          {' '}
          |{' '}
        </span>
        <br
          css={css`
            display: inline;
            ${Desktop} {
              display: none;
            }
          `}
        />
        {intl.formatMessage({ id: 'footer.address' })}
      </div>
    </div>
  );
};

const Disclaimer = () => {
  const intl = useIntl();
  return (
    <div
      css={css`
        font-size: 10px;
        line-height: 20px;
        margin-top: 40px;
      `}
    >
      {intl.formatMessage({ id: 'footer.disclaimer' })}
    </div>
  );
};
const goTop = () => {
  // debugger;
  document.getElementById('___gatsby').scrollTop = 0;
};
const LogoAndApp = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        ${Tablet} {
          justify-content: space-between;
        }
        ${Desktop} {
          width: 180px;
          margin-right: 80px;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }
      `}
    >
      {/* <Link to='/'>
        <div
          css={css`
            width: 160px;
            margin-bottom: 30px;
            margin-right: 40px;
            ${Tablet} {
              width: 180px;
              margin-right: 0px;
            }
          `}
        >
          <Logo type='footer' />
        </div>
      </Link> */}
      <AppLink />
    </div>
  );
};

const AppLink = () => {
  const location = useLocation();
  // const isWhite = ['institutions', 'professional-investors'].includes(
  //   location.pathname.split('/')[3]
  // );
  const isWhite = false;
  const { appStoreUrl } = useAppContext();
  return (
    <div
      css={css`
        display: flex;
        margin-bottom: 40px;
        ${Desktop} {
          display: block;
          width: 80px;
          height: 80px;
          position: relative;
        }
      `}
    >
      {isWhite ? (
        <QRCodeWhite
          css={css`
            display: none;
            ${Desktop} {
              display: block;
            }
          `}
        />
      ) : (
        <QRCode
          css={css`
            display: none;
            ${Desktop} {
              display: block;
            }
          `}
        />
      )}

      <a
        // href="javascript:void(0)"
        href='#'
        target='_blank'
        onClick={(e) => {
          // 阻止默认行为
          if (e && e.preventDefault(e)) {
            e.preventDefault();
          } else {
            window.event.returnValue = false;
          }
          // 阻止事件冒泡
          e.stopPropagation();

          window.location.href = window.apkDownloadUrl;
        }}
      >
        <div
          css={css`
            width: 112px;
            height: 35px;
            margin-right: 20px;
            ${Desktop} {
              position: absolute;
              left: 100px;
              top: 0px;
            }
          `}
        >
          {isWhite ? <GooglePlayWhite /> : <GooglePlay />}
        </div>
      </a>

      <a href={appStoreUrl} target='_blank' rel='noreferrer'>
        <div
          css={css`
            width: 112px;
            height: 35px;
            ${Desktop} {
              position: absolute;
              left: 100px;
              bottom: 0;
            }
          `}
        >
          {isWhite ? <AppStoreWhite /> : <AppStore />}
        </div>
      </a>
    </div>
  );
};

const Footer = ({
  footerForm = false,
  footerBand = true,
  footerBlank = false,
}) => {
  const intl = useIntl();
  const { footerFormRef, appStoreUrl } = useAppContext();
  // 下载APP
  const downloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const reg = /android/i;
    if (reg.test(userAgent)) {
      window.location.href = window.apkDownloadUrl;
    } else {
      window.open(appStoreUrl, '_blank');
    }
  };

  const location = useLocation();
  const isBespoke = ['bespoke', 'appointment'].includes(
    location.pathname.split('/')[4]
  );
  const WealthTechEl = Object.keys(menu['wealthTech'][0]).map((productKey) => {
    if (productKey === 'Solutions') {
      return;
    }
    return <SiteMapList key={productKey} title={'wealthTech'} productMenu />;
  });
  const rightBtnWrap = (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${Tablet} {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        ${Desktop} {
          display: block;
        }
      `}
    >
      <ReqDemoBtn />
      <div
        css={css`
          text-align: center;
          cursor: pointer;
          ${Tablet} {
            margin-left: 30px;
          }
          ${Desktop} {
            margin-top: 18px;
          }
        `}
        onClick={goTop}
      >
        <span className='font700'>
          {intl.formatMessage({ id: `footer.back-top` })}
        </span>
        {/*         <img
          src={`/footer/download_mobile.png`}
          css={css`
            width: 32px;
            margin-right: 10px;
          `}
        ></img> */}
        <img
          css={css`
            width: 22px;
            height: 22px;
            margin-left: 8px;
            vertical-align: -4px;
          `}
          src={`/footer/back-top.png`}
        ></img>
      </div>
    </div>
  );
  return (
    <div ref={footerFormRef} id='footer'>
      {footerBlank && <Blank height={[100, 100, 100]} />}
      <Helmet>
        {/* 移动端safari浏览器，用户在下拉框（select元素）选择时会放大页面。
        解决办法有：1. input输入框中的字体不能小于16px 。 2.也可以禁止页面缩放。
        下面采取的方法就是禁止页面缩放 */}
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      {footerBand && (
        <div
          css={css`
            width: 100%;
            min-width: 320px;

            * {
              fill: #f2f2f2;
            }
          `}
        ></div>
      )}
      {footerForm && <FooterForm />}
      <Wrapper>
        <Container>
          <FirstRow>
            {/* 【底部menu】- IPAD端 */}
            <SiteMap
              css={css`
                display: none;
                ${Tablet} {
                  display: flex;
                  margin-bottom: 62px;
                }
                ${Desktop} {
                  display: none;
                }
              `}
            >
              <div
                css={css`
                  ${Tablet} {
                    min-width: 25%;
                    display: flex;
                    flex-direction: column;
                  }
                  ${Desktop} {
                    display: none;
                  }
                `}
              >
                {/* Product的目录，特殊处理 */}
                {WealthTechEl}
              </div>
              {/* 非Product的目录 */}
              {Object.keys(menu).map((key) => {
                if (key === 'wealthTech') {
                  return;
                }
                return <SiteMapList key={key} title={key} />;
              })}
              {rightBtnWrap}
            </SiteMap>
            {/* 【底部menu】- WEB端 */}
            <SiteMap
              css={css`
                display: none;
                ${Tablet} {
                  display: none;
                }
                ${Desktop} {
                  display: flex;
                }
              `}
            >
              {WealthTechEl}

              {Object.keys(menu).map((key) => {
                if (key === 'wealthTech') {
                  return;
                }
                return <SiteMapList key={key} title={key} />;
              })}
              {rightBtnWrap}
            </SiteMap>
            {/* 【底部menu】- MOBILE */}
            <SiteMap
              css={css`
                display: block;
                margin-bottom: 62px;
                ${Tablet} {
                  display: none;
                }
                ${Desktop} {
                  display: none;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  width: 62%;
                  ${Tablet} {
                    display: none;
                  }
                `}
              >
                {WealthTechEl}
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  width: 62%;
                  ${Tablet} {
                    display: none;
                  }
                `}
              >
                <SiteMapList key={'S-T-S'} title={'S-T-S'} />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin-top: 30px;
                  margin-bottom: 42px;
                  width: 100%;
                  ${Tablet} {
                    display: none;
                  }
                `}
              >
                {Object.keys(menu).map((key) => {
                  if (key === 'wealthTech' || key === 'S-T-S') {
                    return;
                  }
                  return <SiteMapList key={key} title={key} />;
                })}
              </div>
              {rightBtnWrap}
            </SiteMap>
          </FirstRow>
          <SecondRow>
            <div>
              <Shield />
              <SocialMedia />
            </div>
            <LogoAndApp />
          </SecondRow>
          <Copyright />
          <Disclaimer />
        </Container>
      </Wrapper>
      {/* 手机端，【下载APP】按钮 */}
      <>
        {!env.isAQMAPP && (
          <div
            css={css`
              height: 60px;
              background-color: rgba(0, 0, 0, 0.76);
              position: fixed;
              bottom: 0px;
              z-index: 1000;
              display: ${isBespoke ? 'none' : 'flex'};
              width: 100%;
              justify-content: center;
              align-items: center;
              ${Tablet} {
                display: none;
              }
            `}
          >
            <img
              src={`/footer/download_mobile.png`}
              css={css`
                width: 32px;
                margin-right: 10px;
              `}
            ></img>
            <span
              css={css`
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.34px;
                color: #ffffff;
                margin-right: 38px;
              `}
            >
              {intl.formatMessage({ id: `footer.download-app.description` })}
            </span>
            <div
              css={css`
                width: 108px;
                height: 34px;
                border-radius: 17px;
                background-color: #ee2f79;
                text-align: center;
                letter-spacing: -0.34px;
                color: #ffffff;
                font-size: 14px;
                font-weight: 600;
                span {
                  line-height: 34px;
                }
              `}
              onClick={downloadApp}
            >
              <FormattedHTMLMessage id={`footer.download-app.btn`} />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Footer;
